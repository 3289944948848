@import "node_modules/bootstrap/scss/bootstrap"
@import url('https://fonts.googleapis.com/css?family=Noto+Sans|Dosis:500')

$colorPrimary: #177CBA
$colorSecondary: #1099FF
$colorAccent: #ffa64a
$colorAccentTwo: #ff7f7f

body
  font-family: 'Noto Sans', sans-serif

h1, h2, h3, h4, h5
  font-family: 'Dosis', sans-serif

h2
  margin-top: 20px
  margin-bottom: 20px

hr
  margin-top: 4rem

header
  position: relative
  text-align: center
  color: #fff
  background-image: url(../../static/img/tri-smart_background.jpg)
  background-repeat: no-repeat
  background-attachment: scroll
  background-position: center center
  background-size: cover
  z-index: 0

  &:after
    content: ''
    position: absolute
    width: 100%
    height: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-image: linear-gradient(to bottom, #00000000 32%, #ffffff 63%, #00000000)
    opacity: .4
    z-index: -1

.header-text
  padding-top: 150px
  padding-bottom: 300px
  color: black

  h1
    font-size: 34px

  @include media-breakpoint-up(sm)
    h1
      font-size: 42px

  @include media-breakpoint-up(md)
    h1
      font-size: 60px


.bike-banner
  text-align: center
  color: #fff
  background-image: url(../../static/img/bike.jpeg)
  background-repeat: no-repeat
  background-attachment: scroll
  background-position: center center
  background-size: cover

  .header-text
    padding-top: 250px
    padding-bottom: 300px

.runner-banner
  text-align: right
  color: #fff
  background-image: url(../../static/img/runner.jpg)
  background-repeat: no-repeat
  background-attachment: scroll
  background-position: center center
  background-size: cover

  .header-text
    padding-top: 150px
    padding-bottom: 300px

nav
  transition: background-color 0.2s ease
  &.collapsed
    background-color: #FFF
    border-bottom: solid 2px

header p.inscyd-header
  padding: 0
  margin: 0

  a
    padding: 0
    display: inline

  img
    max-width: 150px
    margin-bottom: 7px

.navbar-nav
  font-size: 18px

  .inscyd
    display: flex
    cursor: pointer

    a
      display: flex
    
    img
      width: 128px

.section
  padding-top: 50px
  padding-bottom: 50px

  &.dark
    background-color: #fafbff

  h1
    margin-bottom: 30px

  &.spaced
    padding: 0
    margin-top: 50px
    background: #d4d4d4

    h1
      margin-top: 20px

  &.about-me
    img
      width: 100%
    p
      text-align: justify

.icon
  background-color: $colorPrimary

  &.one
    background-color: $colorAccent

  &.two
    background-color: $colorAccentTwo

.iconcontainer
  position: relative
  height: 200px
  width: 200px
  margin: 20px auto

.main-icon
  height: 200px
  width: 200px
  position: absolute
  margin: 0 auto
  top: 50%
  margin-top: -100px

  img
    height: 200px
    width: 200px
    padding: 30px

.listcontent
  ul
    padding: 0 20px
    font-size: 18px

.enumeration
  position: relative
  margin-top: 60px

  .enum
    padding-left: 25px
    padding-right: 25px
    padding-bottom: 30px

    p
      padding-left: 30px

    img
      width: 22px
      position: absolute
      top: 1px

.results
  margin-top: 40px

.contact
  #contactform label
    margin-bottom: 0

  textarea
    height: 194px
    resize: none
  .buttonrow
    text-align: center
    margin-top: 10px
    margin-bottom: 10px

    &.confirmation
      text-align: left
    &.error
      color: red
    &.success
      color: green

  button
    cursor: pointer

.sticky-footer
  position: fixed
  bottom: 0
  background-color: #FFF
  border-top: solid 2px

  p
    padding-top: 8px
    padding-bottom: 8px
    margin: 0
    text-align: center

footer
  text-align: center
  padding-top: 20px
  padding-bottom: 62px
  background: #505050

  a, span
    color: #eaeaea
  
  a
    text-decoration: underline

.impressum
  h1, h2
    margin-top: 40px

.archievements
  margin-top: 40px

#recaptcha
  display: inline-block

.sendrow
  margin-top: 20px

.embla
  overflow: hidden

.embla__container
  display: flex

.embla__slide__inner
  position: relative
  padding-left: 10px

.embla__slide
  position: relative
  width: 333px

.embla__slide.has-loaded
  width: inherit

.embla__slide__img
  width: auto
  height: auto
  max-height: 500px

#slideshow
  height: 500px
  margin: 36px 0

#events
  .event
    margin-bottom: 35px

#news
  h3
    margin-top: 30px

#your-plan
  h4
    margin-top: 1em

img.inscyd
  max-width: 300px

.postfeed
  display: grid
  gap: 4.8vmin 4vmin
  grid-template-columns: repeat(auto-fit,minmax(min(350px,100%),1fr))

.postfeed-posts
  display: flex
  flex-direction: column

.postthumb
  display: block
  height: auto
  max-width: 100%
  aspect-ratio: 3/2
  object-fit: contain
  width: 100%

.plans
  overflow-x: auto

  td:nth-child(1)
    color: #4e4e4e

  tr:nth-child(even)
    background: #f7f7f7

  tr:nth-child(even) > td:nth-child(2)
    background: #f9eed8

  td:nth-child(2), th:nth-child(2)
    background: #fff7e6

  th
    text-align: center
    font-size: 1.4em

  tr > *
    padding: 12px 16px
    vertical-align: top

.header-badge
  position: relative

.header-badge img
  position: absolute
  right: 0
  top: 80px
  animation: float 6s ease-in-out infinite

@keyframes float
  0%
    transform: translatey(-10px)
  50%
    transform: translatey(10px)
  100%
    transform: translatey(-10px)

.action-card
  background-color: #f05439
  padding: 1rem
  border-radius: 12px
  color: white
  font-weight: bold
  font-size: 1.2rem
  text-align: center
